<template>
  <v-dialog :value="value" class="dialog" persistent width="1350">
    <v-card color="#fafafa">
      <v-toolbar dark flat color="#1C3144">
        <v-btn icon dark @click="$emit('update:value', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Add Feature Item {{hasMultipleFeatures ? '(Multiple Features)' : null}}</v-toolbar-title>
        <v-spacer />
        <v-col cols="2" align="right">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class="mx-2" small fab color="orange" :disabled="isItemEmpty || isItemCopied" height="40"
                @click="copyToClipboard">
                <v-icon>mdi-content-duplicate</v-icon>
              </v-btn>
            </template>
            <span>Copy</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" small fab :disabled="isItemEmpty || !isItemCopied" height="40" color="red"
                @click="clearClipboard">
                <v-icon>mdi-cancel</v-icon>
              </v-btn>
            </template>
            <span>Clear</span>
          </v-tooltip>
        </v-col>
      </v-toolbar>
      <v-container class="ma-0 pa-6">
        <v-row dense>
          <v-col cols="2">
            <v-text-field
              @keydown.enter="getByItemCode"
              @click="featureSelect = true"
              autocomplete="off"
              background-color="#fff"
              dense
              height="40"
              label="Item Code"
              :messages="selectedFeatures.length > 0 && Object.keys(selectedFeature).length > 0 ? 'Feature Selected' : ''"
              outlined
              ref="itemCode"
              v-model="item.orderCode">
              <template v-slot:append>
                <v-row dense>
                  <v-btn @click="getByItemCode" icon>
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </v-row>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field readonly dense background-color="#fff" outlined label="Pack" v-model="item.pack" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field readonly dense background-color="#fff" outlined label="Size" v-model="item.size" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field readonly dense background-color="#fff" outlined label="UPC" v-model="item.UPC" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col cols="5">
            <v-text-field readonly dense background-color="#fff" outlined label="Item Description" v-model="item.description"
              autocomplete="off">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="2">
            <v-text-field dense background-color="#fff" outlined type="number" step="0.01" min="0" prefix="$"
              label="Case Cost" v-model="item.cost" @change="formatCurrencyInput('cost', item.cost)" autocomplete="off">
              <template v-slot:append>
                <v-btn-toggle v-if="!hasMultipleFeatures" dense mandatory v-model="appliedCaseCost">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" small icon>
                        <v-icon small :color="appliedCaseCost ? 'green' : 'grey'">mdi-check</v-icon>
                      </v-btn>
                    </template>
                    <span>{{appliedCaseCost === 0 ? 'Applied to Item Only' : 'Apply to Item'}}</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" small icon>
                        <v-icon small :color="!appliedCaseCost ? 'green' : 'grey'">mdi-check-all</v-icon>
                      </v-btn>
                    </template>
                    <span>{{appliedCaseCost === 1 ? 'Applied to Linked Items' : 'Apply to Linked Items'}}</span>
                  </v-tooltip>
                </v-btn-toggle>
              </template>
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$" type="number" step="0.01" min="0"
              outlined label="AMAP" v-model="item.AMAP" @change="formatCurrencyInput('AMAP', item.AMAP)" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$" type="number" step="0.01" min="0"
              outlined label="Ad BB" v-model="item.adBB" @change="formatCurrencyInput('adBB', item.adBB)" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$" type="number" step="0.01" min="0"
              outlined label="Ad Scan" v-model="item.adScan" @change="formatCurrencyInput('adScan', item.adScan)" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$" type="number" step="0.01" min="0"
              outlined label="EBA" v-model="item.EBA" @change="formatCurrencyInput('EBA', item.EBA)" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$" type="number" step="0.01" min="0"
              outlined label="ePay" v-model="item.ePay" @change="formatCurrencyInput('ePay', item.ePay)" autocomplete="off">
            </v-text-field>
          </v-col>
        <!-- </v-row>
        <v-row dense> -->
          <v-col>
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$" type="number" step="0.01" min="0"
              max="1" outlined label="Compete" v-model="item.compete" @change="formatCurrencyInput('compete', item.compete)" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$" type="number" step="0.01" min="0"
              outlined label="PCAF" v-model="item.PCAF" @change="formatCurrencyInput('PCAF', item.PCAF)" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field dense background-color="#fff" outlined label="Identifier" v-model="item.identifier"
              autocomplete="off">
          </v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-divider/>
      <v-container class="ma-0">
        <v-data-table
          :loading="loading"
          :height="tableSize"
          :headers="headers"
          :items.sync="hasMultipleFeatures ? items : linkedItems" :search="search"
          :items-per-page="500"
          :show-select="!hasMultipleFeatures"
          v-model="selected"
          :no-data-text="hasMultipleFeatures ? 'Enter Item Code to View Available Items' : 'Enter Item Code to View Available Linked Group Items'"
          item-key="item.orderCode"
          hide-default-footer
          dense
          fixed-header
          class="table"
          :group-by="hasMultipleFeatures ? 'featureInfo' : ''">

          <template v-slot:[`group.header`]="{ group, items, headers }">
            <td v-if="hasMultipleFeatures" class="primary--text font-weight-medium" :colspan="headers.length">{{group}}</td>
          </template>

          <template v-slot:top>
              <v-toolbar flat dense >
                <v-spacer/>
                <v-toolbar-title>{{hasMultipleFeatures ? 'Items to Add' : 'Linked Group Items'}}</v-toolbar-title>
                <v-spacer/>
              </v-toolbar>
              <v-divider/>
          </template>

          <template v-slot:[`item.item.cost`]="{ item }">
            {{formatCurrencyDisplay(item.item.cost)}}
          </template>

        </v-data-table>
      </v-container>
      <v-divider/>
      <v-toolbar flat color="#fafafa">
        <v-row>
          <v-col v-if="!hasMultipleFeatures" cols="3">
            <v-text-field :disabled="!linkedItems.length" outlined dense background-color="#fff" v-model="search"
            prepend-inner-icon="mdi-filter-outline" label="Filter Linked Group Items" />
          </v-col>
          <v-spacer/>
          <v-col v-if="selected.length" class="mt-2" align="right">
            <span class="blue--text">
              <i>{{selected.length}} Selected</i>
            </span>
          </v-col>
          <v-spacer/>
          <v-col cols="4" align="right">
            <v-btn v-if="selected.length && appliedCaseCost !== 1" color="#0c67a5" height="40" class="white--text mx-2" @click="caseCostModal=true">
              Modify Case Cost<v-icon right>mdi-update</v-icon>
            </v-btn>
            <v-btn :disabled="isItemEmpty" color="#00B24A" height="40" class="white--text" @click="addItem">
              Add<v-icon right>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
    </v-card>

    <v-dialog :value="caseCostModal" persistent width="400">
      <v-card>
        <v-toolbar dark flat color="#1C3144">
          <v-btn icon @click="caseCostModal=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Case Cost Update</v-toolbar-title>
        </v-toolbar>
        <v-container class="ma-0 pa-6">
          <v-row justify="center" dense>
            <v-col cols="6">
              <v-text-field outlined dense background-color="#fff" v-model="caseCostValue"
                @change="formatCurrencyInput('caseCostValue', caseCostValue)" label="Case Cost" prefix="$"/>
            </v-col>
          </v-row>
        </v-container>
        <v-divider/>
        <v-toolbar flat>
          <v-spacer/>
          <v-btn height="40" class="white--text" color="green" @click="updateCaseCost">
            Update<v-icon right>mdi-update</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { isEmpty } from 'lodash'
import { tableSettings } from '@/mixins/table'
import { notification } from '@/mixins/notification'
import { utils } from '@/mixins/utils'

export default {
  name: 'FeatureItemAdd',

  mixins: [tableSettings, notification, utils],

  props: [
    'value',
    'selectedFeature',
    'selectedFeatures',
    'selectedItem',
    'getFeatureItems',
    'viewAllItems'
  ],

  data () {
    return {
      appliedCaseCost: 0,
      caseCostModal: false,
      caseCostValue: '',
      clipboard: {},
      item: {},
      items: [],
      loading: null,
      linkedItems: [],
      pageSize: 500,
      search: '',
      selected: [],
      addItemFeauture: {},
      featureSelect: false
    }
  },

  computed: {
    headers () {
      return [
        {},
        {
          text: 'Order Code',
          align: 'start',
          sortable: true,
          filterable: true,
          value: this.hasMultipleFeatures ? 'orderCode' : 'item.orderCode',
          class: 'black--text'
        },
        {
          text: 'Pack',
          align: 'start',
          sortable: true,
          filterable: true,
          value: this.hasMultipleFeatures ? 'pack' : 'item.pack',
          class: 'black--text'
        },
        {
          text: 'Size',
          align: 'start',
          sortable: true,
          filterable: true,
          value: this.hasMultipleFeatures ? 'size' : 'item.size',
          class: 'black--text'
        },
        {
          text: 'UPC',
          align: 'start',
          sortable: true,
          filterable: true,
          value: this.hasMultipleFeatures ? 'UPC' : 'item.UPC',
          class: 'black--text'
        },
        {
          text: 'Item Description',
          align: 'start',
          sortable: true,
          filterable: true,
          value: this.hasMultipleFeatures ? 'description' : 'item.description',
          class: 'black--text'
        },
        {
          text: 'Cost',
          align: 'start',
          sortable: true,
          filterable: true,
          value: this.hasMultipleFeatures ? 'caseCost' : 'item.cost',
          class: 'black--text'
        },
      ]
    },

    isItemEmpty () {
      return isEmpty(this.item)
    },

    isItemCopied () {
      return !isEmpty(this.clipboard)
    },

    hasMultipleFeatures() {
      return this.selectedFeatures.length > 0
    }
  },

  watch: {
    value: {
      immediate: true,
      handler (value) {
        if (value) {
          this.linkedItems = []
          this.selected = []
          this.item = {}
          this.clipboard = {}
          this.errors = []
        }
      }
    },

    item: {
      immediate: true,
      deep: true,
      handler () {
        for (const key in this.item.Item) {
          this.item[key] = this.item.Item[key]
        }
      }
    },

    caseCostModal: {
      handler (value) {
        if (value) {
          this.caseCostValue = ''
        }
      }
    }
  },

  created () {
    this.nonTableHeight = 495
  },

  methods: {
    selectFeature(feature) {
      console.log('first')
      this.$emit('selectFeature', feature)
      setTimeout(() => {
        this.getByItemCode()
      }, 500);
    },

    copyToClipboard () {
      this.clipboard = { ...this.item }
      this.notify('success', 'Item Successfully Copied')
    },

    clearClipboard () {
      this.clipboard = {}
      this.getByItemCode(this.item.OrderCode)
      this.notify('success', 'Clipboard Successfully Cleared')
    },

    formatCurrencyInput (key, value) {
      this.item[key] = ((value * 100) / 100).toFixed(2)
      this.$forceUpdate()
    },

    formatCurrencyDisplay (value) {
      return '$' + parseFloat(value).toFixed(2)
    },

    async getItemByFeature () {
      try {
        const promises = this.selectedFeatures.map(feature => {
          return this.$contracts.getByItemCode(this.item.orderCode, feature.supplier.id)
          .then(response => {
            response[0].adName = feature.adName
            response[0].featureId = feature.featureId
            response[0].featureInfo = feature.groupHeader = `${feature.adName} | ${feature.brandCombo}`
            return response
          })
        })
        const { fulfilled, rejected } = await this.getAllSettled(promises, true)

        if (fulfilled.length > 0) {
          this.items = fulfilled
          const { 0: item } = this.items
          this.item = {
            description: item.description,
            itemCode: item.itemCode,
            orderCode: item.orderCode,
            pack: item.pack,
            UPC: item.UPC,
            size: item.size,
            regSRP: item.regSRP,
            regSRPCount: item.regSRPCount,
            cost: isEmpty(this.clipboard) ? item.caseCost : this.clipboard.cost,
            AMAP: isEmpty(this.clipboard) ? '0.00' : this.clipboard.AMAP,
            adBB: isEmpty(this.clipboard) ? '0.00' : this.clipboard.adBB,
            adScan: isEmpty(this.clipboard) ? '0.00' : this.clipboard.adScan,
            EBA: isEmpty(this.clipboard) ? '0.00' : this.clipboard.EBA,
            ePay: isEmpty(this.clipboard) ? '0.00' : this.clipboard.ePay,
            compete: isEmpty(this.clipboard) ? '0.00' : this.clipboard.compete,
            PCAF: isEmpty(this.clipboard) ? '0.00' : this.clipboard.PCAF,
            identifier: isEmpty(this.clipboard) ? 'ADVRD1' : this.clipboard.identifier
          }
        }

        if (rejected.length > 0) throw rejected

      } catch (errors) {
        this.notify('error', 'Item Not Available for Selected Feature(s)')
      }
    },

    async getByItemCode () {
      if (this.hasMultipleFeatures) return this.getItemByFeature()

      await this.$contracts.getByItemCode(this.item.orderCode, this.selectedFeature.supplier.id)
        .then(response => {
          this.item.description = response[0].description
          this.item.itemCode = response[0].itemCode
          this.item.orderCode = response[0].orderCode
          this.item.pack = response[0].pack
          this.item.size = response[0].size
          this.item.UPC = response[0].UPC
          this.item.cost = isEmpty(this.clipboard) ? response[0].caseCost : this.clipboard.cost
          this.item.AMAP = isEmpty(this.clipboard) ? '0.00' : this.clipboard.AMAP
          this.item.adBB = isEmpty(this.clipboard) ? '0.00' : this.clipboard.adBB
          this.item.adScan = isEmpty(this.clipboard) ? '0.00' : this.clipboard.adScan
          this.item.EBA = isEmpty(this.clipboard) ? '0.00' : this.clipboard.EBA
          this.item.ePay = isEmpty(this.clipboard) ? '0.00' : this.clipboard.ePay
          this.item.compete = isEmpty(this.clipboard) ? '0.00' : this.clipboard.compete
          this.item.PCAF = isEmpty(this.clipboard) ? '0.00' : this.clipboard.PCAF
          this.item.identifier = isEmpty(this.clipboard) ? 'ADVRD1' : this.clipboard.identifier
          this.formatCurrencyInput('cost', this.item.cost)
          this.getLinkedGroups()
        }).catch(() => {
          this.notify('error', 'Item Not Available')
          this.item = {}
          this.linkedItems = []
        })
    },

    async getLinkedGroups () {
      this.loading = true
      await this.$contracts.getProductLinkGroup(this.item.orderCode, this.selectedFeature.supplier.id)
        .then(response => {
          this.linkedItems = response.filter(x => x.orderCode !== this.item.orderCode)
          this.linkedItems.forEach(linkedItem => {
            linkedItem.featureId = this.selectedFeature.featureId
            linkedItem.item = {
              orderCode: linkedItem.orderCode,
              itemCode: linkedItem.itemCode,
              pack: linkedItem.pack,
              size: linkedItem.size,
              UPC: linkedItem.UPC,
              description: linkedItem.description,
              cost: linkedItem.caseCost
            }
            linkedItem.cost = linkedItem.caseCost
            delete linkedItem.caseCost
            delete linkedItem.orderCode
            delete linkedItem.itemCode
            delete linkedItem.pack
            delete linkedItem.size
            delete linkedItem.UPC
            delete linkedItem.description
            delete linkedItem.regSRP
            delete linkedItem.regSRPCount
          })
        }).catch(() => {
          this.notify('error', 'No Linked Items Available')
        })
      this.loading = false
    },

    updateCaseCost () {
      this.linkedItems.forEach(linkedItem => {
        linkedItem.item.cost = this.caseCostValue
      })
      this.notify('success', 'Cost Successfully Updated')
      this.caseCostModal = false
    },

    async addMultipleItems () {
      try {
        const promises = this.items.map(item => {
          item = {
            adName: item.adName,
            featureId: item.featureId,
            item: {
              ...item,
              cost: this.item.cost,
            },
            ...this.item
          }
          return this.$ads.saveFeatureItem(item)
        })
        const { fulfilled, rejected } = await this.getAllSettled(promises, true)

        if (fulfilled.length === this.selectedFeatures.length) {
          this.notify('success', `${this.selectedFeatures.length} Items Successfully Added`)
        }
        
        this.item = {}
        this.items = []
        if (rejected.length > 0) throw rejected
      } catch (errors) {
        const showErrors = errors.map(error => error.reason.config.data).map(feature => {
          const { orderCode, adName } = JSON.parse(feature)
          return `Item ${orderCode} already exists on ${adName}`
        })
        this.showErrors(showErrors)
      } finally {
        this.viewAllItems()
      }
    },

    async addItem () {
      if (this.hasMultipleFeatures) return this.addMultipleItems()
      this.item.featureId = this.selectedFeature.featureId

      this.item.item = {
        cost: this.item.cost,
        description: this.item.description,
        itemCode: this.item.itemCode,
        orderCode: this.item.orderCode,
        pack: this.item.pack,
        size: this.item.size,
        UPC: this.item.UPC
      }

      if (this.selected.length) {
        this.selected.push(this.item)

        const errors = []
        const promises = []

        this.selected.forEach(linkedItem => {
          if (this.appliedCaseCost === 1) {
            linkedItem.item.cost = this.item.item.cost
          }

          for (const key in this.item) {
            if (!Object.prototype.hasOwnProperty.call(key, linkedItem) && key !== 'item') {
              linkedItem[key] = this.item[key]
            }
          }

          promises.push(
            this.$ads.saveFeatureItem(linkedItem)
              .then().catch(() => {
                errors.push(`${linkedItem.item.orderCode} ${linkedItem.item.description} Not Added`)
              })
          )
        })

        await Promise.allSettled(promises)

        errors.length ? this.showErrors(errors) : this.notify('success', this.selected.length ? 'Items Successfully Added' : 'Item Successfully Added')
      } else {
        await this.$ads.saveFeatureItem(this.item)
          .then(() => {
            this.notify('success', 'Item Successfully Added')

            if (!isEmpty(this.clipboard)) {
              delete this.clipboard.orderCode
              delete this.clipboard.itemCode
              delete this.clipboard.pack
              delete this.clipboard.size
              delete this.clipboard.UPC
              delete this.clipboard.description

              this.item = this.clipboard
            }
          }).catch(err => {
            this.notify('error', err?.response?.data?.error)
          })
      }

      this.getFeatureItems(this.selectedFeature)
      this.item = {}
      this.linkedItems = []
      this.selected = []
      this.appliedCaseCost = 0
      // this.$refs.itemCode.$el.focus()
    }
  }
}
</script>

<style scoped>
.v-expansion-panel >>> .v-expansion-panel-content__wrap {
  padding: 0px;
}
</style>
